
/**
 * Cwu settings for the CWU types of projects
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import {mapGetters, mapActions} from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import {ActionMessage} from "@/model/Messages/statusMessage";
import {WEP_MOCK_URL} from "@/shared/keycloak";
import UserAccess from "@/model/User/UserAccess";
import VwaSettingsFullSize from "@/components/project/SingleProject/Schematic/VwaSettingsFullSize.vue";
import VwaPreWarningLamp from "@/components/project/SingleProject/Schematic/VwaPreWarningLamp.vue";

import {Projects} from "@/services/projectService";
import VwaSettingsTMD from "@/components/project/SingleProject/Schematic/VwaSettingsTMD.vue";
import DisplayType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/DisplayType.vue";
import VwaType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/VwaType.vue";

@Component({
  name: "VwaSettings",
  components: {
    VwaSettingsTMD,
    Multiselect,
    VwaSettingsFullSize,
    VwaPreWarningLamp
  },
  computed: {
    ...mapGetters("projects", {
      currentSection: "GetCurrentSectionDetails",
      manualOverrideOptions: "GetManualOverrideOptionsFromState",
      currentProject: "GetCurrentProject"

    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("projects", {
      getSectionsDetails: "GetSectionDetail",
      GetManualOverrideSignOption: "GetManualOverrideSingOptions",
      manualOverrideToggle: "CWUManualOverrideToggle",
      manualOverrideToggleVWA: "VWAManualOverrideToggle",
      getAllSectionFromTheProject: "GetAllSectionFromTheProject",
      getSectionDetailsFromBackEnd: "GetSectionDetail",
    })
  }
})
export default class VwaSettings extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  readonly currentSection!: Section;
  private readonly manualOverrideOptions!: object;
  readonly currentProject!: Project;
  readonly getUserAccess!: UserAccess;


  private vwaLetters!: any;
  private getSectionsDetails!: Function;
  private GetManualOverrideSignOption!: Function;
  private manualOverrideToggle!: Function;
  private manualOverrideToggleVWA!: Function;
  private getAllSectionFromTheProject!: Function;
  private getSectionDetailsFromBackEnd!: Function;

  /*----------  Props  ----------*/
  @Prop({type: Object, default: () => ({})}) sectionInformation!: Section;
  @Prop({type: String, default: "48x48"}) displaySize!: string;
  readonly vwaSettings = this.sectionInformation.state.vwaSettings;

  // VWA Select Buttons "Tab"
  private optionsVWA: { value: number, label: string }[] = [
    {value: 1, label: 'Fullsize VWA'},
    {value: 2, label: 'T/M/B VWA'},
    // {value: 3, label: 'Text VWA'}
  ];
  private selectedOptionVWA: number | null = (this.sectionInformation.state.vwaSettings?.filename) ? 1 : ((this.sectionInformation.state.vwaSettings?.text !== '') ? 3 : 2);

  // TODO: alles auslagern in interface oder so
  // TODO: Default immer "" setzten und die Werte erst in created oder mounted schreiben
  private filename: String = (this.vwaSettings?.filename) ? this.vwaSettings?.filename : "noPicture.jpg";

  // VWA Top
  private filenameTop: String = (this.vwaSettings?.filenameTop) ?? "";
  private folderTop: String = "TrafficSignImagesTop64x56";

  // VWA Middle
  private filenameMid: String = (this.vwaSettings?.filenameMid) ?? "";
  private folderMid: String = "TrafficSignImagesMid64x72";

  // VWA Bottom
  private filenameBottom: String = (this.vwaSettings?.filenameBottom) ?? "";
  private folderBottom: String = "TrafficSignImagesBottom64x16";

  // VWA Text
  private textSizeOptions: { value: number, label: string }[] = [
    {value: 1, label: '7x4 bis 7x5 Pixel'},
    {value: 2, label: '14x10 Pixel'}
  ];
  private textSize: any = ( (this.vwaSettings?.textSize) ? this.textSizeOptions[this.vwaSettings?.textSize - 1] : "" );
  private text: any = this.vwaSettings?.text ;
  private prewarningEnabled: any = false;
  private isValidText: boolean = true;
  private allowCharacterTooltip: String = "";

  textLedSign: any = ((this.vwaSettings?.text != "") ? this.vwaSettings?.text : "");
  html: any = "";

  /*----------  Local data  ----------*/
  isLoading = false;
  vwaUpdateStatus: ActionMessage | null = null;

  localManualOverride: any = false;
  width: number = 64;

  inputText: any = ((this.vwaSettings?.text != "") ? this.vwaSettings?.text : "");
  schildWidth: number =  240; // Breite des Schilds
  schildHeight: number = 450; // Höhe des Schilds
  allowedCharacters: any = [];
  maxRow: number = 18;
  swapSelects: boolean = false;

  selectionChangeMid: any = "";
  selectionChangeBottom: any = "";

  onInit: boolean = true;
  disableSubmit: boolean = true;

  resetKeyTop: number =  1000;
  resetKeyMid: number = 2000;
  resetKeyBottom: number = 3000;
  private vwaTypeInstance: any = null;

  /*----------  Vue life cycles  ----------*/
  created() {
    this.GetManualOverrideSignOption(this.sectionInformation.id);
    this.fetchAllVwaLetters();
    this.watcher();

    // Wenn es "geswaped" wurde
    if(this.filenameMid.charAt(0) === 'm') {
      this.swapSelects = true;

    } else {

      var bottomCopy = this.filenameBottom;

      this.filenameBottom = this.filenameMid;
      this.filenameMid = bottomCopy;

      this.makeCopyOfSelections();

    }
  }

  mounted() {
    this.getSectionsDetails(this.sectionInformation.id).then(() => {
      if (this.currentSection.state.cwuSettings) {
        this.localManualOverride = this.currentSection.state.cwuSettings.manualOverride;
      }
    });

    this.renderDataOnLoad();
    this.disableSubmitButton();

  }

  watcher() {
    this.$watch('filenameTop', this.filenameTopWatcher);
    this.$watch('filenameMid', this.filenameMidWatcher);
    this.$watch('filenameBottom', this.filenameBottomWatcher);
    // this.$watch('selectionChangeMid', this.$emit('update-img', "test"))
    // this.$watch('selectionChangeBottom', this.$emit('update-img', "test"))
  }

  filenameTopWatcher() {
    this.resetKeyTop++;
  }

  filenameMidWatcher() {
    this.resetKeyMid++;
  }

  filenameBottomWatcher() {
    this.resetKeyBottom++;
  }

  reset() {
    setTimeout(() => {
      this.vwaUpdateStatus = null;
    }, 2000)
  }

  renderDataOnLoad() {
    this.prewarningEnabled = this.vwaSettings?.prewarningEnabled;
  }

  async fetchAllVwaLetters() {

    this.vwaLetters = "";

    try {
      const res = await Projects.GetAllVwaLetters();
      this.vwaLetters = res.data;
      var count = 0;

      // TODO: Übersetzung
      this.allowCharacterTooltip = "Die Buchstaben die erlaubt sind:    \n "
      res.data.forEach((value: any, key: any) => {

        count++;

        this.allowedCharacters.push(value.letter, " ", "\n");
        this.allowCharacterTooltip += `  '${value.letter}' `;

        if(count !=  res.data.length) {
          this.allowCharacterTooltip += ", ";
        }
      });

      this.allowCharacterTooltip;

    } catch (error) {
      console.error("Fehler beim Abrufen der Daten:", error);
    }

  }

  selectOptionVWA(optionValue: number) {
    if (this.selectedOptionVWA === optionValue) {
      this.selectedOptionVWA = null;
    } else {
      this.selectedOptionVWA = optionValue;
    }
  }

  handleSelection(selectionData: any) {

    // FullSize Img
    if(selectionData.folder === '') {

      if(selectionData.selectedOption) {
        this.filename = selectionData.selectedOption.value;
      } else {
        this.filename = "";
      }

      // T/M/B IMG
    } else {

      switch (selectionData.folder) {
        case 'TrafficSignImagesTop64x56':
          if (selectionData.selectedOption) {
            this.filenameTop = selectionData.selectedOption.value;
          } else {
            this.filenameTop = "";
          }

          this.folderTop = 'TrafficSignImagesTop64x56';

          break;

        case 'TrafficSignImagesMid64x72':
          if (selectionData.selectedOption) {
            this.filenameMid = selectionData.selectedOption.value;
          } else {
            this.filenameMid = "";
          }

          this.folderMid = 'TrafficSignImagesMid64x72';

          break;

        case 'TrafficSignImagesBottom64x16':
          if (selectionData.selectedOption) {
            this.filenameBottom = selectionData.selectedOption.value;
          } else {
            this.filenameBottom = "";
          }

          this.folderBottom = 'TrafficSignImagesBottom64x16';

          break;

      }
    }

    this.disableSubmitButton();
  }

  swapSelection() {

    this.swapSelects = !this.swapSelects;

    if(!this.swapSelects) {
      this.makeCopyOfSelections()

    } else {

      this.filenameMid = this.selectionChangeMid.value;
      this.filenameBottom = this.selectionChangeBottom.value
    }
  }

  makeCopyOfSelections() {

    var optionsBottom: any = this.$t(`project.section.displayInterval.optionsBottom`);
    var optionsMid: any= this.$t(`project.section.displayInterval.optionsMiddle`);

    this.selectionChangeMid = optionsMid.find( (option: any) => option.value === this.filenameMid);
    this.selectionChangeBottom = optionsBottom.find( (option: any) => option.value === this.filenameBottom);

  }

  async submitVWA() {

    // Workaround -- Please not Touch it
    if(this.filename === 'noPicture.jpg') {
      this.filename = "";
    }

    const manualOverrideSettings = {
      id: this.currentSection.state.vwaSettings?.id,
      sectionId: this.currentSection.id,
      filename: this.filename,
      filenameTop: this.filenameTop,
      filenameMid: this.filenameMid,
      filenameBottom: this.filenameBottom,
      text: this.textLedSign,
      textSize: this.textSize.value,
      prewarningEnabled: this.prewarningEnabled
    };

    if(!this.swapSelects) {
      manualOverrideSettings.filenameMid = ((this.selectionChangeBottom) ? this.selectionChangeBottom.value : "");
      manualOverrideSettings.filenameBottom = ((this.selectionChangeMid) ? this.selectionChangeMid.value : "");
    }

    switch(this.selectedOptionVWA) {

      case 1:
        manualOverrideSettings.filenameTop = "";
        manualOverrideSettings.filenameMid = "";
        manualOverrideSettings.filenameBottom = "";
        manualOverrideSettings.text = "";
        manualOverrideSettings.textSize = 0;

        break;

      // T/M/D
      case 2:
        manualOverrideSettings.filename = "";
        manualOverrideSettings.text = "";
        manualOverrideSettings.textSize = 0;

        // TODO: Set text to null or empty string
        break;

      // Text - Textsize
      case 3:
        manualOverrideSettings.filename = "";
        manualOverrideSettings.filenameTop = "";
        manualOverrideSettings.filenameMid = "";
        manualOverrideSettings.filenameBottom = "";
        break;

    }

    const updateSignVWA = await this.manualOverrideToggleVWA({
      MOSettings: manualOverrideSettings,
      sectionId: this.currentSection.id
    });

    this.reset();
    await this.getAllSectionFromTheProject(this.$route.params.id);

    if(updateSignVWA.status === 200) {
      this.vwaUpdateStatus = {
        class: "success",
        msg: this.$t(`project.section.displayInterval.successMsg`)
      };

    } else {
      this.vwaUpdateStatus = {
        class: "danger",
        msg: this.$t(`project.section.displayInterval.errorMsg`)
      };
    }
  }

  // LED Text
  // --------
  // --------

  lookupLetter() {
    this.checkTextValidity();
    this.textLedSign = this.inputText;
  }

  updateMaxRow(optionChange: boolean) {

    // Abfrage mit Bestätigung
    this.maxRow = 18;
    const lines = this.inputText.split('\n');

    if(this.textSize && this.textSize.value == 2) {

      if(optionChange) {

        this.$bvModal
          .msgBoxConfirm(this.$t("project.section.vwa.textSizeChangeConfirm").toString(), {
            title: (this as any).$t("project.section.vwa.textSizeConfirmHeader"),
            size: "md",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0 bg-light",
            footerClass: "p-2 border-top-0",
            centered: true,
            okTitle: (this as any).$t("utils.alertBox.ok"),
            cancelVariant: "light",
            cancelTitle: (this as any).$t("utils.alertBox.cancel")
          })
          .then((value: boolean) => {
            if (value) {

              this.text = this.inputText;
              this.inputText = "";
              this.textLedSign = "";

              // this.maxRow = 9;
              // this.textSize = this.textSizeOptions[1];
              //
              // if (lines.length > 9) {
              //   this.inputText = lines.slice(0, 9).join('\n');
              // }
              //
              // this.textLedSign = this.inputText;

            } else {
              this.textSize = this.textSizeOptions[0];
            }
          });
      } else {
        this.maxRow = 9;
      }

    } else {
      this.textSize = this.textSizeOptions[0];

      if (lines.length > 18) {
        this.inputText = lines.slice(0, 18).join('\n');
      }

      this.textLedSign = this.inputText;

    }

    this.textLedSign = this.inputText;

  }

  handleKeyDown(event: any) {

    this.updateMaxRow(false);

    // If is a letter and not Enter/ Backspache/ ArrowLeft ...
    if(event.key.length == 1) {

      if(this.allowedCharacters.includes(event.key)) {

        // If Something pressed
        if(event.key.length === 1 && this.isValidText) {

          var currentLineWidth: any = 0;
          var nextCharacterWidth: any = 0;
          const currentLineText = this.getCurrentLineText(event.target);


          // First Letter
          if(currentLineText == "") {
            currentLineWidth += this.calculateCharacterWidth(event.key);
            currentLineWidth += 1;

          } else {

            // Character of letters before
            for (const char of currentLineText) {

              // fix width of space
              if (char === " ") {
                currentLineWidth += 1;
              } else {
                const charWidth = this.calculateCharacterWidth(char);

                currentLineWidth += charWidth;
                currentLineWidth += 1;

              }
            }

            // Calculate current Character Width
            nextCharacterWidth = this.calculateCharacterWidth(event.key);
          }

          const totalWidth = currentLineWidth + nextCharacterWidth;

          // must fit in the current line
          if (totalWidth <= 64) {
            return true;

            // prevent entering character
          } else {
            event.preventDefault();
            return false;
          }
        }

      } else {

        event.preventDefault();

        // NOT Allowed to write Character
        this.isValidText = false;

        setTimeout(() => {
          this.isValidText = true;
        }, 1000)
      }
    }

    // Max Rows Limit on 9 or 18
    else if (event.key === 'Enter' && this.inputText.split('\n').length >= this.maxRow) {
      event.preventDefault();
    }


  }

  getCurrentLineText(textarea: any) {

    // current cursor position
    const cursorPosition = textarea.selectionStart;

    if(this.inputText) {

      // text before and after cursor to calculate width
      var textBeforeCursor = this.inputText.substring(0, cursorPosition);
      var textAfterCursor = this.inputText.substring(cursorPosition);

      // find last enter
      const lastLineBreakIndex = textBeforeCursor.lastIndexOf('\n');

      const newText = textBeforeCursor + textAfterCursor;

      // if not enter exists -> then its the first row
      if (lastLineBreakIndex === -1) {
        return newText ;

        // return all character after the enter
      } else {
        // Wenn ein Zeilenumbruch gefunden wurde, extrahiere den Text ab dem Zeilenumbruchzeichen bis zur aktuellen Cursorposition
        return newText.substring(lastLineBreakIndex + 1);
      }

    } else {
      return "";
    }
  }

  calculateCharacterWidth(char: any) {

    if (char === " ") {
      return 1;
    } else {
      for (const value of this.vwaLetters) {
        if (value.letter === char) {

          // Consider depending on whether Font 1 or Font 2 was selected
          if(this.textSize.value == 2) {
            return parseInt(value.font2);

          } else {
            return parseInt(value.font1);
          }
        }
      }
    }

    return false;

  }

  checkTextValidity() {
    for (let i = 0; i < this.inputText.length; i++) {
      if (!this.allowedCharacters.includes(this.inputText[i])) {
        this.isValidText = false;
        return;
      }
    }

    this.isValidText = true;
  }


  disableSubmitButton() {

    var res: any = true;
    var deviceState: any = this.currentSection.state.stateDetails.deviceStateOne;

    if(!deviceState.in4) {

      this.disableSubmit = res;
      return;

    } else {

      switch (this.selectedOptionVWA) {

        // FUllsize
        case 1:

          if(this.filename) {
            res = false;
          }

          break;

        // T/M/D
        case 2:

          if(!this.swapSelects) {

            if( (this.filenameTop != '' && this.selectionChangeMid && this.selectionChangeBottom) ||
              (this.filenameTop == '' && !this.selectionChangeMid && !this.selectionChangeBottom) ||
              (this.filenameTop == '' && this.selectionChangeMid == '' &&  this.selectionChangeBottom == '')) {
              res = false;
            }

          } else {

            if( (this.filenameTop != '' && this.filenameMid != '' && this.filenameBottom != '') ||
              (this.filenameTop == '' && this.filenameMid == '' && this.filenameBottom == '') ) {
              res = false;
            }
          }

          break;

        // TExt
        case 3:


          if(this.textSize) {
            if(this.inputText !== '' && this.textSize.value >= 1 && this.isValidText) {
                res = false;
            }
          }

          break;
      }

      this.disableSubmit = res;
    }

  }

  resetTopMidBottomImg() {
    this.filenameTop = "";
    this.filenameMid = "";
    this.filenameBottom = "";
    this.prewarningEnabled = false;
    this.selectionChangeMid = null;
    this.selectionChangeBottom = null;

    this.disableSubmitButton();
  }


}
